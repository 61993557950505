<template>
  <div>
    <div v-if="loading" class="text-center">
      <a-spin tip="Loading..." />
    </div>
    <a-form-model
      v-if="!loading"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="name" label="グループ名" prop="name">
        <a-input
          v-model="form.name"
          @blur="
            () => {
              $refs.name.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item ref="comment" label="メモ" prop="comment">
        <a-input
          v-model="form.comment"
          @blur="
            () => {
              $refs.comment.onFieldBlur()
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit" :class="$style.filledBtn">
          {{ submitString }}
        </a-button>
        <router-link :to="backPath">
          <a-button style="margin-left: 10px;">
            戻る
          </a-button>
        </router-link>
      </a-form-model-item>
    </a-form-model>
    <div v-if="submitType === 'put'">
      <p class="text-right"><a-button type="danger" @click="deleteClick">削除する</a-button></p>
    </div>
    <a-modal
      title="本当に削除しますか？"
      :visible="visible"
      :confirm-loading="confirmLoading"
      okText="削除する"
      okType='danger'
      cancelText='キャンセル'
      @ok="handleOk"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>

import Vue from 'vue'
import { objectData } from '@/services/object'

export default {
  name: 'FormUser',
  data() {
    return {
      loading: true,
      confirmLoading: false,
      visible: false,
      submitType: this.$route.meta.submitType,
      submitString: this.$route.meta.submitString,
      backPath: '/company',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      group: Object.assign({}, objectData.group),
      form: {
        name: '',
        comment: '',
      },
      rules: {
        name: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
          { max: 255, message: '255文字までです。', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    group: function(val) {
      this.form.name = val.name
      this.form.comment = val.comment
    },
  },
  methods: {
    onSubmit() {
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (_this.submitType === 'post') {
            const send = Vue.prototype.$api.send('post', 'groups', this.form)
            send.then(response => {
              _this.$notification['success']({
                message: this.form.name + ' を作成しました。',
              })
              _this.$router.push(this.backPath)
            })
              .catch(error => {
                if (error.status === 403) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          } else if (_this.submitType === 'put') {
            const send = Vue.prototype.$api.send('put', 'groups/' + _this.$route.params.id, this.form)
            send.then(response => {
              _this.$notification['success']({
                message: this.form.name + ' を更新しました。',
              })
              _this.$router.push(this.backPath)
            })
              .catch(error => {
                if (error.status === 403) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deleteClick() { this.visible = true },
    handleCancel() { this.visible = false },
    handleOk() {
      const _this = this
      this.confirmLoading = true
      const result = Vue.prototype.$api.send('delete', 'groups/' + _this.$route.params.id)
      result.then(response => {
        this.$notification['success']({
          message: this.group.name + ' を削除しました',
        })
        this.$router.push('/company')
        this.confirmLoading = false
        this.visible = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 削除に失敗しました。',
          })
          this.loading = false
        })
    },
  },
  created() {
    const _this = this

    if (this.submitType === 'put') {
      const result = Vue.prototype.$api.send('get', 'groups/' + this.$route.params.id)
      result.then(response => {
        this.loading = false
        this.group = response
      })
        .catch(error => {
          _this.$notification['error']({
            message: error.status + ': グループデータの取得に失敗しました。',
          })
          this.loading = false
        })
    } else {
      this.loading = false
    }
  },
}
</script>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
